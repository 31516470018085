import icnGH from './../icons/provider/gamehall.png';
import icnSA from './../icons/provider/sa.png';
import icnJoker from './../icons/provider/joker.png';
import icnEBet from './../icons/provider/ebet.png';
import icnSLotto from './../icons/provider/slotto.png';
import icnUFASlot from './../icons/provider/ufaslot.png';
import icnITP from './../icons/provider/itp.png';
//import icnUFAFishing from './../icons/provider/ufafishing.png';
import icnUFABet from './../icons/provider/ufabet.png';
import icnVS from './../icons/provider/vs.png';
import icnAEGaming from './../icons/provider/aegaming.png';
import icnEvo from './../icons/provider/evo.png';
import icnBbin from './../icons/provider/bbin.png';
import icnAep from './../icons/provider/aep.png';
import icnPg from './../icons/provider/pegasus.png';
import icnWld from './../icons/provider/we.png';
import icnVpower from './../icons/provider/vpower.png';
import icnEvoRacing from './../icons/provider/evo-racing.png';
//import icnYesGaming from './../icons/provider/yesgaming.png';
import icnIdealGaming from './../icons/provider/idealgaming.png';
import icnAsiaGaming from './../icons/provider/ag.png';
import icnPT from './../icons/provider/playtech.png';
import icnYGR from './../icons/provider/ygr.png';

const gameUrl = process.env.VUE_APP_GAME_URL;
const gameUrl2 = process.env.VUE_APP_GAME_URL2;

var gameProvider = [
  {
    name: 'SA Gaming',
    code: '31',
    icon: icnSA,
    platform: [],
    gameUrl: gameUrl2,
    enabled: true,
  },
  {
    name: 'Game Hall',
    code: '39',
    icon: icnGH,
    platform: [],
    gameUrl: gameUrl,
    enabled: true,
  },
  {
    name: 'Joker',
    code: '35',
    icon: icnJoker,
    platform: [],
    gameUrl: gameUrl2,
    enabled: true,
  },
  {
    name: 'eBet',
    code: '64',
    icon: icnEBet,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'ITP',
    code: '67',
    icon: icnITP,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },

  {
    name: 'UFA Slot',
    code: '42',
    icon: icnUFASlot,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'VPOWER',
    code: '72',
    icon: icnVpower,
    platform: [],
    gameUrl: gameUrl,
    enabled: true,
  },
  //{
  //  name: 'UFA Fishing',
  //  code: '72',
  //  icon: icnUFAFishing,
  //  platform: [],
  //  gameUrl: gameUrl,
  //  enabled: false,
  //},
  {
    name: 'AE Gaming',
    code: '76',
    icon: icnAEGaming,
    platform: [],
    gameUrl: gameUrl,
    enabled: false,
  },
  {
    name: 'Evolution',
    code: '77',
    icon: icnEvo,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'BBIN LIVE',
    code: '78',
    icon: icnBbin,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'AE Poker',
    code: '79',
    icon: icnAep,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'Muay Step',
    code: '44',
    icon: icnUFABet,
    platform: [],
    gameUrl: gameUrl,
    enabled: false,
  },
  {
    name: 'SLotto',
    code: '41',
    icon: icnSLotto,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'Virtual Sport',
    code: '50',
    icon: icnVS,
    platform: [],
    gameUrl: gameUrl2,
    enabled: false,
  },
  {
    name: 'Pegasus',
    code: '80',
    icon: icnPg,
    platform: [],
    gameUrl: gameUrl2,
    enabled: true,
  },
  {
    name: 'World Entertainment',
    code: '81',
    icon: icnWld,
    platform: [],
    gameUrl: gameUrl2,
    enabled : false,
  },
  {
    name: 'Evo Racing',
    code: '98',
    icon: icnEvoRacing,
    platform: [],
    gameUrl: gameUrl2,
    enabled : false,
  },
  {
    name: 'iDealGaming',
    code: '100',
    icon: icnIdealGaming,
    platform: [],
    gameUrl: gameUrl2,
    enabled : true,
  },
  {
    name: 'YGR',
    code: '101',
    icon: icnYGR,
    platform: [],
    gameUrl: gameUrl2,
    enabled : true,
  },
  {
    name: 'Asia Gaming',
    code: '108',
    icon: icnAsiaGaming,
    platform: [],
    gameUrl: gameUrl2,
    enabled : true,
  },
  {
    name: 'Playtech',
    code: '109',
    icon: icnPT,
    platform: [],
    gameUrl: gameUrl2,
    enabled : true,
  },
];

export default gameProvider;
