import gameImport from './../game';

import joker from './../../images/game/slot/img-joker-d.png';
import spade from './../../images/game/slot/img-spadegaming-d.png';
import fachai from './../../images/game/slot/img-fachai-d.png';
import jili from './../../images/game/slot/img-jili-d.png';
import redtiger from './../../images/game/slot/img-redtiger-d.png';
import king from './../../images/game/slot/img-kingmaker-d.png';
import pragmatic from './../../images/game/slot/img-pragmaticplay-d.png';
import play8 from './../../images/game/slot/img-play8-d.png';
import pegasus from './../../images/game/slot/img-pegasus-d.png';
//import genesis from './../../images/game/slot/img-genesis.png';
import vpower from './../../images/game/slot/img-vpower.png';
import fastspin from './../../images/game/slot/img-fastspin.png';
import dragon from './../../images/game/slot/img-dragon.png';
import nlc from './../../images/game/slot/img-nolimitcity.png';
//import yesgame from './../../images/game/slot/v-sport-yes-gaming-s-min.png';
import jdbslot from './../../images/game/slot/v-sport-jdb-s-min.png';
import idg from './../../images/game/slot/v-sport-IDG-s-min.png';
import ygr from './../../images/game/slot/img-ygr-d.png';
import pt from './../../images/game/slot/img-playtech.png';

var gameMenu = {
  name: 'SLOT',
  items: [
    {
      name: 'PEGASUS',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '80').icon,
      code: '80-201',
      img: [{ langId: 0, src: pegasus }],
      img2: [{ langId: 0, src: pegasus }],
    },
    {
      name: 'JOKER',
      provider: 'joker',
      icon: gameImport.find((x) => x.code.toString() === '35').icon,
      code: '35',
      img: [{ langId: 0, src: joker }],
      img2: [{ langId: 0, src: joker }],
    },
     {
      name: 'VPOWER',
      provider: 'Vpower',
      icon: gameImport.find((x) => x.code.toString() === '72').icon,
      code: '72-101',
      img: [{ langId: 0, src: vpower }],
      img2: [{ langId: 0, src: vpower }],
    },
    {
      name: 'SPADE GAMING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-205',
      img: [{ langId: 0, src: spade }],
      img2: [{ langId: 0, src: spade }],
    },
    {
      name: 'FA CHAI',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-207',
      img: [{ langId: 0, src: fachai }],
      img2: [{ langId: 0, src: fachai }],
    },
    {
      name: 'JILI',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-208',
      img: [{ langId: 0, src: jili }],
      img2: [{ langId: 0, src: jili }],
    },
    {
      name: 'RED TIGER',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-202',
      img: [{ langId: 0, src: redtiger }],
      img2: [{ langId: 0, src: redtiger }],
    },
    {
      name: 'KING MIDAS',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: king }],
      img2: [{ langId: 0, src: king }],
    },
    {
      name: 'PRAGMATIC PLAY',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-203',
      img: [{ langId: 0, src: pragmatic }],
      img2: [{ langId: 0, src: pragmatic }],
    },
    {
      name: 'PLAY8',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-210',
      img: [{ langId: 0, src: play8 }],
      img2: [{ langId: 0, src: play8 }],
    },
    //  {
    //   name: 'GENESIS',
    //   provider: 'ebet',
    //   icon: gameImport.find((x) => x.code.toString() === '64').icon,
    //   code: '64-101',
    //   img: [{ langId: 0, src: genesis }],
    //   img2: [{ langId: 0, src: genesis }],
    // },
    {
      name: 'Fast Spin',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-212',
      img: [{ langId: 0, src: fastspin }],
      img2: [{ langId: 0, src: fastspin }],
    },
    {
      name: 'Dragoon Soft',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-213',
      img: [{ langId: 0, src: dragon }],
      img2: [{ langId: 0, src: dragon }],
    },
    {
      name: 'No Limit City',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-214',
      img: [{ langId: 0, src: nlc }],
      img2: [{ langId: 0, src: nlc }],
    },
    // {
    //   name: 'iDealGaming',
    //   provider: 'iDealGaming',
    //   icon: gameImport.find((x) => x.code.toString() === '100').icon,
    //   code: '100',
    //   img: [{ langId: 0, src: idg }],
    //   img2: [{ langId: 0, src: idg }],
    // },
    {
      name: 'JDB Slot',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-201',
      img: [{ langId: 0, src: jdbslot }],
      img2: [{ langId: 0, src: jdbslot }],
    },
    {
      name: 'iDealGaming',
      provider: 'iDealGaming',
      icon: gameImport.find((x) => x.code.toString() === '100').icon,
      code: '100',
      img: [{ langId: 0, src: idg }],
      img2: [{ langId: 0, src: idg }],
    },
    {
      name: 'YGR',
      provider: 'YGR',
      icon: gameImport.find((x) => x.code.toString() === '101').icon,
      code: '101',
      img: [{ langId: 0, src: ygr }],
      img2: [{ langId: 0, src: ygr }],
    },
    {
      name: 'PLAYTECH',
      provider: 'playtech',
      icon: gameImport.find((x) => x.code.toString() === '109').icon,
      code: '109',
      img: [{ langId: 0, src: pt }],
      img2: [{ langId: 0, src: pt }],
    },
  ],
};

export default gameMenu;
